import fbt from 'fbt';

import { Gender } from '#enum';

export const genderList = [
  { label: `${fbt('Male', 'gender')}`, value: Gender.Male },
  { label: `${fbt('Female', 'gender')}`, value: Gender.Female },
  {
    label: `${fbt('I prefer not to say', 'gender')}`,
    value: Gender.Unknown,
  },
];

export default null;
