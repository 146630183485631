import { isLeapYear } from 'date-fns';
import fbt from 'fbt';

import { createNumberArray } from '#utils';

// eslint-disable-next-line no-unused-expressions
fbt;

const createDropdownItems = (
  numbers: number[],
  add = 0,
): { label: string; value: number }[] =>
  numbers.map((n) => {
    const addedNumber = n + add;
    return { label: addedNumber.toString(), value: addedNumber };
  });

const currentYear = new Date().getUTCFullYear();

export const yearList = createNumberArray(80).map((year) => {
  const y = currentYear - year;
  return {
    label: y.toString(),
    value: y,
  };
});

export const monthList = [
  { label: `${fbt('January', 'month')}`, value: 0 },
  { label: `${fbt('February', 'month')}`, value: 1 },
  { label: `${fbt('March', 'month')}`, value: 2 },
  { label: `${fbt('April', 'month')}`, value: 3 },
  { label: `${fbt('May', 'month')}`, value: 4 },
  { label: `${fbt('June', 'month')}`, value: 5 },
  { label: `${fbt('July', 'month')}`, value: 6 },
  { label: `${fbt('August', 'month')}`, value: 7 },
  { label: `${fbt('September', 'month')}`, value: 8 },
  { label: `${fbt('October', 'month')}`, value: 9 },
  { label: `${fbt('November', 'month')}`, value: 10 },
  { label: `${fbt('December', 'month')}`, value: 11 },
];

const dayArray = createDropdownItems(createNumberArray(31), 1);
export const getDayList = (
  month = 0,
  year = new Date().getUTCFullYear(),
): { label: string; value: number }[] => {
  const isLeap = isLeapYear(new Date(year, month));

  if (month === 1) return dayArray.slice(0, isLeap ? 29 : 28);
  if ([3, 5, 8, 10].includes(month)) return dayArray.slice(0, 30);
  return dayArray;
};

export default null;
