import { Dialog } from 'react-native-paper';
import styled from 'styled-components/native';

import Button from '@/Button';
import {
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from '#styled-system';

export const ActionButton = styled(Button)<
  SpaceProps & ColorProps & TypographyProps
>`
  margin-horizontal: 8px;
  ${space};
  ${color};
  ${typography};
`;

export const ButtonContainer = styled(Dialog.Actions)`
  padding: 24px 0 0;
  font-weight: 500;
`;

export default null;
