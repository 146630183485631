/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const Message = styled(Text)`
  color: ${(props: any) => props.theme.colors.text};
  font-size: 16px;
`;

export const ConfirmationForm = styled.View``;

export const LabelContainer = styled.View`
  margin-bottom: 14px;
`;

export const Label = styled(Text)`
  margin-bottom: 6px;
  color: ${(props: any) => props.theme.colors.text};
  font-size: 14px;
`;

export const ConfirmMessage = styled(Text)`
  color: ${(props: any) => props.theme.colors.red};
  font-weight: 600;
  font-size: 14px;
`;

export default null;
