import fbt from 'fbt';
import React from 'react';
import { Paragraph } from 'react-native-paper';

import DialogActionButtons from '@/DialogActionBttons';
import ModalCard from '@/ModalCard';
import TextInput from '@/TextInput';
import useFbt from '#hooks/useFbt';

import {
  ConfirmationForm,
  ConfirmMessage,
  Label,
  LabelContainer,
  Message,
} from './ConfirmationDialog.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  title?: string;
  message: React.ReactElement | string;
  confirmMessage?: string;
  visible?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  noPadding?: boolean;
} & React.ComponentProps<typeof DialogActionButtons>;

const ConfirmationDialog = ({
  title,
  message,
  confirmMessage,
  visible = false,
  disabled,
  isLoading,
  submitLabel,
  submitProps,
  cancelLabel,
  cancelProps,
  ...props
}: Props): React.ReactElement => {
  useFbt();

  const [confirmText, setConfirmText] = React.useState('');
  const content =
    typeof message === 'string' ? <Paragraph>{message}</Paragraph> : message;

  return (
    <ModalCard
      title={title}
      width="500px"
      visible={visible}
      onDismiss={cancelProps?.onPress}
      {...props}
    >
      <Message>{content}</Message>
      {confirmMessage && (
        <ConfirmationForm>
          <LabelContainer>
            <Label>
              <fbt desc="label">Please type the following to confirm:</fbt>
            </Label>
            <ConfirmMessage>{confirmMessage}</ConfirmMessage>
          </LabelContainer>
          <TextInput onChangeText={setConfirmText} />
        </ConfirmationForm>
      )}
      <DialogActionButtons
        isLoading={isLoading}
        disabled={
          !!disabled ||
          (confirmMessage ? confirmMessage !== confirmText : undefined)
        }
        submitLabel={submitLabel}
        submitProps={submitProps}
        cancelLabel={cancelLabel}
        cancelProps={cancelProps}
      />
    </ModalCard>
  );
};

export default React.memo(
  ConfirmationDialog,
  (prev, next) =>
    prev.isLoading === next.isLoading &&
    prev.visible === next.visible &&
    prev.confirmMessage === next.confirmMessage &&
    prev.disabled === next.disabled &&
    prev.submitProps?.disabled === next.submitProps?.disabled &&
    prev.cancelProps?.disabled === next.cancelProps?.disabled,
);
