const getAvailablePreferedLocales = (
  preferedLocales: (string | undefined | null)[],
  availableLocales: string[],
): string[] => {
  const lang = preferedLocales.reduce((res, preferedLocale) => {
    if (preferedLocale) {
      const matchedLocale = availableLocales.find(
        (aLocale) =>
          aLocale === preferedLocale.replace('-', '_') ||
          aLocale === preferedLocale.replace('_', '-') ||
          (preferedLocale.length === 2 &&
            aLocale.slice(0, 2) === preferedLocale),
      );

      if (matchedLocale)
        return res.includes(matchedLocale) ? res : [...res, matchedLocale];

      const format = preferedLocale.slice(0, 2);
      if (availableLocales.includes(format))
        return res.includes(format) ? res : [...res, format];

      return res;
    }
    return res;
  }, [] as string[]);

  if (lang?.length) return lang;
  return ['en_US'];
};

export default getAvailablePreferedLocales;
