import fbt from 'fbt';
import React from 'react';
import {
  ActivityIndicator,
  Button,
  NativeSyntheticEvent,
  NativeTouchEvent,
} from 'react-native';
import { Except, PartialDeep } from 'type-fest';

import useFbt from '#hooks/useFbt';
import useResponsive from '#hooks/useResponsive';
import useTheme from '#hooks/useTheme';
import { ColorProps, SpaceProps, TypographyProps } from '#styled-system';

import { ActionButton, ButtonContainer } from './DialogActionButtons.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type ButtonProps = PartialDeep<
  Except<React.ComponentProps<typeof Button>, 'onPress'>
> & {
  onPress?: (ev?: NativeSyntheticEvent<NativeTouchEvent>) => void;
} & SpaceProps &
  ColorProps &
  TypographyProps;

type Props = {
  disabled?: boolean;
  isLoading?: boolean;
  submitLabel?: string;
  submitProps?: ButtonProps;
  cancelLabel?: string;
  cancelProps?: ButtonProps;
};

const DialogActionButtons = ({
  disabled,
  isLoading,
  submitLabel,
  submitProps,
  cancelLabel,
  cancelProps,
}: Props) => {
  useFbt();

  const [, { lteSm }] = useResponsive();
  const { colors } = useTheme();

  const buttonProps = React.useMemo(
    () =>
      lteSm
        ? {
            marginStart: '12px',
          }
        : {
            uppercase: false,
            fontSize: '14px',
          },
    [lteSm],
  );

  return (
    <ButtonContainer>
      <ActionButton
        disabled={disabled}
        color={colors?.text3}
        {...buttonProps}
        {...cancelProps}
      >
        {cancelLabel ?? <fbt desc="button label">Cancel</fbt>}
      </ActionButton>
      {submitProps?.onPress && (
        <ActionButton
          disabled={disabled}
          color={colors?.blue}
          {...buttonProps}
          {...submitProps}
        >
          {submitLabel ?? <fbt desc="button label">Submit</fbt>}
        </ActionButton>
      )}
      {isLoading && <ActivityIndicator size={16} />}
    </ButtonContainer>
  );
};

export default React.memo(DialogActionButtons);
