import React from 'react';
import DropdownPicker from 'react-native-dropdown-picker';

import useTheme from '#hooks/useTheme';
import getInputMaxWidth from '#styles/helpers/getInputMaxWidth';

type Props = { size?: 's' | 'm' | 'l' } & React.ComponentProps<
  typeof DropdownPicker
>;

const Dropdown = ({
  size,
  style,
  containerStyle,
  labelStyle,
  placeholderStyle,
  dropDownStyle,
  itemStyle,
  ...props
}: Props): React.ReactElement => {
  const { colors } = useTheme();

  const s = React.useMemo(
    () => ({
      style: [
        { backgroundColor: colors?.surface, borderColor: colors?.light },
        style,
      ],
      container: [
        { height: 35, maxWidth: getInputMaxWidth(size) },
        containerStyle,
      ],
      label: [{ marginStart: 4, color: colors?.text }, labelStyle],
      placeholder: [
        { marginStart: 0, color: colors?.placeholder },
        placeholderStyle,
      ],
      dropDown: [
        {
          backgroundColor: colors?.floatBox?.backgroundColor,
          borderColor: colors?.light,
        },
        dropDownStyle,
      ],
      item: [
        {
          justifyContent: 'flex-start' as const,
          backgroundColor: colors?.floatBox?.backgroundColor,
        },
        itemStyle,
      ],
    }),
    [
      colors?.floatBox?.backgroundColor,
      colors?.light,
      colors?.placeholder,
      colors?.surface,
      colors?.text,
      containerStyle,
      dropDownStyle,
      itemStyle,
      labelStyle,
      placeholderStyle,
      size,
      style,
    ],
  );

  return (
    <DropdownPicker
      style={s.style}
      containerStyle={s.container}
      labelStyle={s.label}
      placeholderStyle={s.placeholder}
      dropDownStyle={s.dropDown}
      itemStyle={s.item}
      arrowColor={colors?.text2}
      {...props}
    />
  );
};

export default React.memo(Dropdown);
